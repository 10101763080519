<template>
    <div class="docusign-container">
        <div
            class="docusign-error-container alert alert-warning"
            v-show="documentNotSignedWarning"
            role="alert"
        >
            <div class="docusign-error-contents">
                <span>{{ documentNotSignedWarning }}</span>
            </div>
        </div>

        <div class="docusign-iframe-mask">
            <loading-indicator
                v-show="isLoading"
                style="position: relative; z-index: 100; background-color: white"
                :title="loadingTitle"
            />
            <iframe
                :src="docuSignUrl"
                title="DocuSign"
                class="docusign-iframe"
                data-testid="docusign-iframe"
                @load="docuSignIframeLoaded"
                @error="docuSignIframeFailedToLoad"
                sandbox="allow-scripts allow-same-origin allow-downloads"
            />
        </div>
    </div>
</template>

<script>
    import assert from 'assert'
    import { logger } from '@/utils/logger'
    import { getApplicantDocuSignState, getApplicantDocuSignUrl, getNotaryDocuSignState, getNotaryDocuSignUrl } from '@/services/docuSignApi'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { SessionAgent } from '@/utils/remoteNotarization'
    import { runWithRetryLogic } from '@/utils/http-client'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'

    const ViewingState = {
        loading: 'loading',
        showingIframe: 'showingIframe',
    }

    export default {
        name: 'DocuSign',
        props: {
            // agent is either applicant or notary
            agent: {
                type: String,
                required: true,
                validator: function (value) {
                    return Object.values(SessionAgent).indexOf(value) !== -1
                },
            },
            // required if agent is notary
            applicantId: { type: Number },
            loanApplicationId: { type: Number },
        },
        components: {
            'loading-indicator': LoadingIndicator,
        },
        data() {
            return {
                docuSignUrl: null, // content to be rendered in iframe
                pollIntervalInMs: Number(appSessionStorage.getItem(localStorageKey.pollInterval) ?? 1000),
                maxWaitTimeForDocusignMsec: 90 * 1000,
                docuSignWatchdogIntervalId: null,
                state: {
                    // default state
                    envelopeId: null,
                    hasApplicantSigned: false,
                    hasNotaryStamped: false,
                },
                isStale: true, // determines whether to cover iframe with loading screen because we are searching for a new url
                statePollingIntervalId: null,
                docuSignLoadedFirstTime: false, // set after the iframe is complete loading
            }
        },
        created: function () {
            window.addEventListener('message', this.signatureCompleteEventHandler, false)
        },
        beforeDestroy: function () {
            window.removeEventListener('message', this.signatureCompleteEventHandler, false)
            this.stopPolling()
        },
        mounted: async function () {
            await this.main()
        },
        computed: {
            loadingTitle() {
                if (this.agent === SessionAgent.notary) {
                    if (!this.state.envelopeId) {
                        return 'Waiting on DocuSign envelope to be generated...'
                    } else if (!this.docuSignLoadedFirstTime) {
                        return 'Waiting on DocuSign to load again'
                    } else {
                        return 'Waiting on something else....'
                    }
                } else {
                    if (!this.docuSignLoadedFirstTime) {
                        return 'Working on your documents! Please wait...'
                    } else {
                        return 'Almost there! Just another minute...'
                    }
                }
            },
            isMissingCredentials() {
                return this.agent === SessionAgent.notary && (!this.applicantId || !this.loanApplicationId)
            },
            viewingState() {
                if (this.isStale || !this.docuSignUrl) return ViewingState.loading
                return ViewingState.showingIframe
            },
            isLoading() {
                return this.viewingState === ViewingState.loading
            },
            documentNotSignedWarning() {
                // Only show document signing warnings on the notary side
                if (this.agent === SessionAgent.notary) {
                    if (!this.state.hasApplicantSigned) {
                        return 'User has not finished signing their document'
                    }

                    if (!this.state.hasNotaryStamped) {
                        return 'Notary has not finished signing the document'
                    }
                }

                // I.e. don't show a message
                return ''
            },
        },
        watch: {
            // attempt to dodge DocuSign loading screen
            docuSignUrl(newVal) {
                this.isStale = true
                logger.log(`DocuSign url loading ${newVal}`)

                // No reason to start up the watchdog timer if we're just clearing the iframe out
                if (newVal !== null) {
                    // We got a new URL, reset any existing watchdog timer
                    this.clearDocuSignWatchdog()
                    logger.log(`Set docuSign watchdog timer to ${this.maxWaitTimeForDocusignMsec} msec`)
                    this.docuSignWatchdogIntervalId = setTimeout(this.docuSignIframeFailedToLoad, this.maxWaitTimeForDocusignMsec)
                }
            },
        },
        methods: {
            main: async function () {
                // retry if notary does not yet have applicant id and loan application id
                if (this.isMissingCredentials) {
                    setTimeout(this.main, this.pollIntervalInMs)
                    return
                }

                // retrieve initial state and url on load
                this.state = await this.getDocuSignState()
                await this.setDocuSignUrl()
                this.beginPolling() // constantly polling
            },
            clearDocuSignWatchdog: function () {
                if (this.docuSignWatchdogIntervalId) {
                    logger.log(`Clearing existing docuSign watchdog timer ${this.docuSignWatchdogIntervalId}`)
                    // clearTimeout does not appear to throw for valid numeric inputs
                    clearTimeout(this.docuSignWatchdogIntervalId)
                    this.docuSignWatchdogIntervalId = null
                }
            },
            docuSignIframeLoaded: async function () {
                // Onload called even when there's no active URL
                if (!this.docuSignUrl) return

                logger.info('DocuSign iframe finished loading!!')

                // No need to reload if we loaded the first time, or if we're on notary
                if (this.docuSignLoadedFirstTime || this.agent !== SessionAgent.applicant) {
                    logger.log('No need to reload again')
                    this.isStale = false
                    // Doc loaded, no need to worry about the watchdog timer :-)
                    this.clearDocuSignWatchdog()
                    return
                }

                // Prevent this func from getting called in a loop
                this.docuSignLoadedFirstTime = true

                // We explicitly keep this.isStale = false to prevent a white screen flash on reload

                logger.log('Attempting to solve improper loading on mobile by double-loading the iframe with a new URL')

                logger.log('Getting second docusign link...')
                const docuSignUrlResponse = await getApplicantDocuSignUrl()
                this.docuSignUrl = docuSignUrlResponse.data.payload.url
            },
            // This func gets called if docuSign times out, or if there was an error event generated by the iFrame
            // May get called more than once, so needs to be safe to get called multiple times
            docuSignIframeFailedToLoad: function (error) {
                // Try and minimize the probability that we're stacking this function call several times
                this.clearDocuSignWatchdog()
                logger.warn(`Docusign failed to load! ${error}`)
                this.$logEvent('event_docusign_failed_to_load', { agent: this.agent })
            },
            signatureCompleteEventHandler: async function (event) {
                if (event.data !== 'docusign-signature-complete') return
                logger.info(`Received event from iframe that signature is complete. Reloading iframe.`)
                this.$logEvent('event_docusign_signature_complete', { agent: this.agent })
                await this.setDocuSignUrl()
            },
            setDocuSignUrl: async function () {
                let docuSignUrlResponse
                try {
                    this.$logEvent('event_getting_docusign_url', { agent: this.agent })
                    switch (this.agent) {
                        case SessionAgent.notary:
                            if (this.isMissingCredentials) {
                                logger.info('Credentials missing, aborting call to get notary DocuSign url')
                                return
                            }
                            docuSignUrlResponse = await getNotaryDocuSignUrl(this.applicantId, this.loanApplicationId)
                            break
                        case SessionAgent.applicant:
                            docuSignUrlResponse = await getApplicantDocuSignUrl()
                            break
                    }

                    assert(docuSignUrlResponse.data?.payload, 'Something went wrong when retrieving the DocuSign url')
                    logger.info('Setting first docusign link')
                    this.docuSignUrl = docuSignUrlResponse.data.payload.url
                } catch (error) {
                    logger.error(`Error retrieving DocuSign url`, null /* event */, error)
                    this.stopPolling()
                    throw error
                }
            },
            getDocuSignState: async function () {
                let docuSignStateResponse
                try {
                    switch (this.agent) {
                        case SessionAgent.notary:
                            if (this.isMissingCredentials) {
                                logger.info('Credentials missing, aborting call to get notary DocuSign state')
                                return
                            }
                            docuSignStateResponse = await runWithRetryLogic(async () => {
                                return await getNotaryDocuSignState(this.applicantId, this.loanApplicationId)
                            }, 2)
                            break
                        case SessionAgent.applicant:
                            docuSignStateResponse = await runWithRetryLogic(async () => {
                                return await getApplicantDocuSignState()
                            }, 2)
                            break
                    }
                    assert(docuSignStateResponse.data?.payload?.state, 'Failed to retrieve DocuSign state')
                    return docuSignStateResponse.data.payload.state
                } catch (error) {
                    logger.error(`Error retrieving DocuSign state`, null /* event */, error)
                    this.stopPolling()
                    throw error
                }
            },
            beginPolling: function () {
                logger.info('Beginning to poll DocuSign state')
                this.statePollingIntervalId = window.setInterval(this.pollForNewDocuSignUrl, this.pollIntervalInMs)
            },
            stopPolling: function () {
                logger.info('Ceasing to poll DocuSign state')
                window.clearInterval(this.statePollingIntervalId)
                this.statePollingIntervalId = null
            },
            pollForNewDocuSignUrl: async function () {
                // 1. Retrieve current state
                const newState = await this.getDocuSignState()

                // 2. Compare against current state
                if (newState && this.isStateStale(newState)) {
                    // 3. If out of date, update state to latest and retrieve a fresh DocuSign url
                    logger.info('DocuSign state has become stale. Retrieving a new DocuSign url.')
                    this.state = newState
                    this.isStale = true
                    await this.setDocuSignUrl()
                }
            },
            isStateStale: function (newState) {
                return !(this.state.envelopeId === newState.envelopeId && this.state.hasApplicantSigned === newState.hasApplicantSigned && this.state.hasNotaryStamped === newState.hasNotaryStamped)
            },
            haveBothPartiesSigned: function () {
                return this.state.hasApplicantSigned && this.state.hasNotaryStamped
            },
        },
    }
</script>

<style lang="scss">
    .docusign-container {
        height: 100%;
        z-index: 2;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .docusign-error-container {
            margin: 10px 20px 0 20px;
            justify-content: center;
            text-align: center;
            flex-shrink: 1;

            .docusign-error-contents {
                width: 100%;
                margin: auto;
            }
        }

        .docusign-iframe-mask {
            z-index: 2;
            overflow: hidden;
            position: relative;
            flex-grow: 1;

            .docusign-iframe {
                width: 100%;
                height: 100%;
                border: none;
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
</style>

import { httpClient } from '@/utils/http-client'
import { logger } from '@/utils/logger'
import { SessionState } from '@/utils/remoteNotarization'

export enum NotaryBusyStatus {
    offline = 'offline',
    idle = 'idle',
    busy = 'busy',
    error = 'error',
}

// Keep in sync with enum PersonaVerificationStatus in aven_backend/src/provider/Persona/personaProvider.ts
export enum PersonaVerificationStatus {
    notStarted = 'not_started',
    pendingComplete = 'pending_complete',
    passed = 'passed',
    failed = 'failed',
    declined = 'declined',
    expired = 'expired',
    needsReview = 'needs_review',
}

// applicant routes
const updateKbaStatus = async (passOrFail: KbaStatus) => {
    return await httpClient.post('remoteNotarization/applicant/updateKbaStatus', {
        passOrFail,
    })
}

const enterApplicantNotarySession = async () => {
    return await httpClient.post('/remoteNotarization/applicant/enterSession', {})
}

const regenerateDocusign = async (applicantNotaryAssignmentId) => {
    return await httpClient.get('/remoteNotarization/notary/regenerateDocusign', {
        params: {
            applicantNotaryAssignmentId,
        },
    })
}

const clearPriorScannedDocumentUpload = async () => {
    return await httpClient.get('/remoteNotarization/notary/clearPriorScannedUpload')
}

// Keep in sync with KbaStatus in applicantNotaryAssignment.ts
export enum KbaStatus {
    incomplete = 'incomplete',
    pass = 'pass',
    // This is to overrule IDology without all of the hax on their data reports
    override = 'override',
    fail = 'fail',
    permanentFail = 'permanentFail',
    // This is our middle ground where they passed some verification but not enough
    allowableFail = 'allowableFail',
    // Same thing as above but requires SSN validation AND 2 forms of ID
    verifySSN = 'verifySSN',
    outOfCountry = 'outOfCountry',
}

export enum SessionType {
    RIN = 'RIN',
    RON = 'RON',
}

// Keep in sync with FlowStatus in applicantNotaryAssignment.ts
export enum FlowStatus {
    sessionScheduled = 'sessionScheduled',
    preQueue = 'preQueue',
    inKba = 'kba',
    inSession = 'inSession',
    sessionComplete = 'sessionComplete',
    failedByNotary = 'failedByNotary',
    sessionMissed = 'sessionMissed',
    canceled = 'canceled',
}

const getNotaryInfo = async () => {
    const response = await httpClient.post('remoteNotarization/applicant/getNotaryInfo', {})
    return response?.data?.payload
}

const getFlowStatus = async () => {
    return await httpClient.get('remoteNotarization/applicant/getFlowStatus', {})
}

// notary routes
const checkForInProgressRoom = async () => {
    const response = await httpClient.get('/remoteNotarization/notary/checkForInProgressRoom')
    return response?.data?.payload
}

const getNotary = async () => {
    return await httpClient.get('/remoteNotarization/notary/getNotary', {})
}

const registerNotary = async (authCode: string) => {
    return await httpClient.post('/remoteNotarization/notary/registerNotary', {
        authCode,
    })
}

const updateNotaryBusyStatus = async (status: NotaryBusyStatus) => {
    return await httpClient.post('/remoteNotarization/notary/updateNotaryBusyStatus', {
        status: status,
    })
}

// Polling func used by applicant panel to see updated viewing status, etc
const getApplicantStatus = async () => {
    const response = await httpClient.get('/remoteNotarization/applicant/getApplicantStatus')
    return response?.data?.payload
}

const getNotaryStatus = async (applicantId: number, loanApplicationId: number) => {
    const response = await httpClient.get('/remoteNotarization/notary/getNotaryStatus', {
        params: {
            applicantId,
            loanApplicationId,
        },
    })
    return response?.data?.payload
}

const getInSessionApplicant = async () => {
    return await httpClient.get('/remoteNotarization/notary/getInSessionApplicant')
}

const beginRemoteNotarizationSession = async (applicantId: number, loanApplicationId: number) => {
    // initializes twilio twilioRoom and returns access tokens for notary
    return await httpClient.post('/remoteNotarization/notary/session/begin', {
        applicantId,
        loanApplicationId,
    })
}

const getDocuSignEnvelopeSigningStatus = async (applicantNotaryAssignmentId: number) => {
    return await httpClient.get('/remoteNotarization/notary/getDocuSignEnvelopeSigningStatus', {
        params: {
            applicantNotaryAssignmentId,
        },
    })
}

const endRemoteNotarizationSession = async (
    applicantId: number,
    loanApplicationId: number,
    state: SessionState.sessionCompleteSuccess | SessionState.sessionCompleteFailure | SessionState.sessionPartialFailure,
    failureMessage?: string,
    failureReason?: NotaryFailureReason,
    sendToL2Notary?: boolean
) => {
    return await httpClient.post('/remoteNotarization/notary/session/end', {
        applicantId,
        loanApplicationId,
        state,
        failureMessage,
        failureReason,
        sendToL2Notary,
    })
}

// Keep in sync with enum in aven_backend/src/entity/referralSource.ts
export enum ReferralFrom {
    friend = 'friend',
    facebook = 'facebook',
    instagram = 'instagram',
    google = 'google',
    directMail = 'directMail',
    other = 'other',
}

export const referralFromText: { [T in ReferralFrom]: string } = {
    [ReferralFrom.friend]: 'A friend',
    [ReferralFrom.facebook]: 'Facebook',
    [ReferralFrom.instagram]: 'Instagram',
    [ReferralFrom.google]: 'Google',
    [ReferralFrom.directMail]: 'Direct mail',
    [ReferralFrom.other]: 'Other',
}

// Keep in sync with NotaryFailureReason aven_backend/src/entity/internalCommunication.ts
export enum NotaryFailureReason {
    FAILED_KBA = 'FAILED_KBA',
    APPLICANT_NO_SHOW = 'APPLICANT_NO_SHOW',
    APPLICANT_TECHNICAL_ISSUES = 'APPLICANT_TECHNICAL_ISSUES',
    AVEN_TECHNICAL_ISSUES = 'AVEN_TECHNICAL_ISSUES',
    APPLICANT_MISSING_DOCUMENTATION = 'APPLICANT_MISSING_DOCUMENTATION',
    APPLICANT_REVIEW = 'APPLICANT_REVIEW',
    APPLICANT_DECLINES = 'APPLICANT_DECLINES',
    DOC_DATA_ISSUE_MARITAL_STATUS = 'DOC_DATA_ISSUE_MARITAL_STATUS',
    DOC_DATA_ISSUE_TRUSTS = 'DOC_DATA_ISSUE_TRUSTS',
    DOC_DATA_ISSUE_NAMES_OR_SIGNER_COUNT = 'DOC_DATA_ISSUE_NAMES_OR_SIGNER_COUNT',
    DOC_DATA_ISSUE_OTHER = 'DOC_DATA_ISSUE_OTHER',
    FRAUD_SUSPECTED = 'FRAUD_SUSPECTED',
    OTHER = 'OTHER',
}

export const notaryFailureReasonText: { [T in NotaryFailureReason]: string } = {
    [NotaryFailureReason.FAILED_KBA]: 'Applicant Failed KBA',
    [NotaryFailureReason.APPLICANT_NO_SHOW]: 'Applicant Passed KBA, Did Not Enter Session',
    [NotaryFailureReason.APPLICANT_TECHNICAL_ISSUES]: 'Applicant Technical Issues',
    [NotaryFailureReason.AVEN_TECHNICAL_ISSUES]: 'Aven Technical Issues',
    [NotaryFailureReason.APPLICANT_MISSING_DOCUMENTATION]: 'Applicant Missing Documentation',
    [NotaryFailureReason.APPLICANT_REVIEW]: 'Applicant Review',
    [NotaryFailureReason.APPLICANT_DECLINES]: 'Applicant Declines',
    [NotaryFailureReason.DOC_DATA_ISSUE_MARITAL_STATUS]: 'Document Issue: Marital Status',
    [NotaryFailureReason.DOC_DATA_ISSUE_TRUSTS]: 'Document Issue: Trusts',
    [NotaryFailureReason.DOC_DATA_ISSUE_NAMES_OR_SIGNER_COUNT]: 'Document Issue: Wrong Signer Names / Count',
    [NotaryFailureReason.DOC_DATA_ISSUE_OTHER]: 'Document Issue: Other',
    [NotaryFailureReason.FRAUD_SUSPECTED]: 'Suspected Fraud',
    [NotaryFailureReason.OTHER]: 'Other',
}

const setReferralSource = async (loanApplicationId: number, source: ReferralFrom, otherDescription?: string) => {
    return await httpClient.post('/remoteNotarization/notary/addReferralSource', {
        loanApplicationId,
        source,
        otherDescription,
    })
}

const applicantDisconnected = async (applicantId: number, loanApplicationId: number) => {
    return await httpClient.post('/remoteNotarization/notary/session/applicantDisconnected', {
        applicantId,
        loanApplicationId,
    })
}

const applicantConnected = async (applicantId: number, loanApplicationId: number) => {
    return await httpClient.post('/remoteNotarization/notary/session/applicantConnected', {
        applicantId,
        loanApplicationId,
    })
}

const processCustomerIDDocument = async (frontB64Image: string, backB64Image: string) => {
    logger.info(`Process customer ID doc`)
    return await httpClient.post('/remoteNotarization/applicant/processCustomerIDDocument', {
        frontB64Image,
        backB64Image,
    })
}

const getInSessionIdVerificationInfo = async (applicantId: number, loanApplicationId: number) => {
    return await httpClient.post('/remoteNotarization/notary/inSessionIdVerificationInfo', {
        applicantId,
        loanApplicationId,
    })
}

const getPreSessionIdVerificationInfo = async (applicantId: number, loanApplicationId: number) => {
    return await httpClient.post('/remoteNotarization/notary/preSessionIdVerificationInfo', {
        applicantId,
        loanApplicationId,
    })
}

const setBackendSessionState = async (state: SessionState, applicantId: number, loanApplicationId: number) => {
    return await httpClient.post('/remoteNotarization/notary/session/setState', {
        state,
        applicantId,
        loanApplicationId,
    })
}

const setApplicantSessionState = async (state: SessionState) => {
    return await httpClient.post('/remoteNotarization/applicant/session/setState', {
        state,
    })
}

const submitPlotterSignature = async (signatureSvgBase64: string) => {
    return await httpClient.post('/remoteNotarization/applicant/submitPlotterSignature', {
        signature: signatureSvgBase64,
    })
}

const logKbaStart = async () => {
    return await httpClient.post('/remoteNotarization/applicant/logKbaStart', {})
}

export {
    getNotaryInfo,
    updateKbaStatus,
    getInSessionApplicant,
    checkForInProgressRoom,
    getNotary,
    regenerateDocusign,
    registerNotary,
    updateNotaryBusyStatus,
    getNotaryStatus,
    getApplicantStatus,
    beginRemoteNotarizationSession,
    getDocuSignEnvelopeSigningStatus,
    endRemoteNotarizationSession,
    setReferralSource,
    applicantDisconnected,
    applicantConnected,
    enterApplicantNotarySession,
    processCustomerIDDocument,
    getInSessionIdVerificationInfo,
    getPreSessionIdVerificationInfo,
    logKbaStart,
    setBackendSessionState,
    getFlowStatus,
    setApplicantSessionState,
    submitPlotterSignature,
    clearPriorScannedDocumentUpload,
}

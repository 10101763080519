import { httpClient } from '@/utils/http-client'
import { logger } from '@/utils/logger'

export const getNotaryDocuSignUrl = async (applicantId: number, loanApplicationId: number) => {
    logger.info(`Retrieving DocuSign signing link for notary`)
    return await httpClient.post('/remoteNotarization/docuSign/notary/getUrl', {
        applicantId,
        loanApplicationId,
    })
}

export const getApplicantDocuSignUrl = async () => {
    logger.info(`Retrieving DocuSign signing link for applicant`)
    return await httpClient.post('/remoteNotarization/docuSign/applicant/getUrl', {})
}

export const getNotaryDocuSignState = async (applicantId: number, loanApplicationId: number) => {
    // logger.info('Retrieving DocuSign state for notary')
    return await httpClient.post('/remoteNotarization/docuSign/notary/getState', {
        applicantId,
        loanApplicationId,
    })
}

export const getApplicantDocuSignState = async () => {
    // logger.info('Retrieving DocuSign state for applicant')
    return await httpClient.post('/remoteNotarization/docuSign/applicant/getState', {})
}

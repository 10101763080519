<template>
    <div class="deed-of-trust-container">
        <div
            class="deed-of-trust-error-container alert alert-warning"
            v-if="viewingState === ViewingState.error"
            role="alert"
        >
            <span v-html="errorText" />
            <div class="d-grid">
                <button
                    class="btn btn-primary mt-3"
                    @click="init"
                >
                    Reset
                </button>
            </div>
        </div>
        <div
            class="deed-of-trust-pdf-container"
            v-else
        >
            <loading-indicator
                v-show="viewingState === ViewingState.loading"
                :title="loadingText"
            />
            <!-- Note: mobile Safari + mobile android have no native PDF viewer support
            Ergo, we preferentially use this wrapper of pdf.js to show them the document
            This viewer doesn't allow doing anything other than just scrolling
            -->
            <div v-if="viewingState === ViewingState.showingIframe && agent === SessionAgent.applicant">
                <pdf
                    v-for="i of numberOfPages"
                    :key="i"
                    :page="i"
                    :src="deedOfTrustBinaryArray"
                />
            </div>
            <!-- Note: this NEEDS to be an embed instead of an iframe.
            Iframe creates an embed internally but only works intermittently on Google Chrome
            We use embed for notary b/c it includes the various printing functionalities -->
            <embed
                v-else-if="viewingState === ViewingState.showingIframe && agent === SessionAgent.notary"
                type="application/pdf"
                :src="deedOfTrustBlobUrl"
                class="deed-of-trust-iframe"
            >
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { getLegalDocument, getLegalDocumentForNotary } from '@/services/api'
    import { logger } from '@/utils/logger'
    import { SessionAgent } from '@/utils/remoteNotarization'
    import pdf from 'vue-pdf'
    import { runWithRetryLogic } from '@/utils/http-client'
    import { getApplicantDocuSignState, getNotaryDocuSignState } from '@/services/docuSignApi'
    import assert from 'assert'

    const ViewingState = {
        error: 'error',
        loading: 'loading',
        showingIframe: 'showingIframe',
    }

    export default {
        name: 'DeedOfTrust',
        props: {
            // agent is either applicant or notary
            agent: {
                type: String,
                required: true,
                validator: function (value) {
                    return Object.values(SessionAgent).includes(value)
                },
            },
            applicantId: { type: Number, default: null },
            loanApplicationId: { type: Number, default: null },
        },
        components: {
            'loading-indicator': LoadingIndicator,
            pdf: pdf,
        },
        data: function () {
            return {
                // This abomination is necessary in order to trigger the first 'watch' state transition
                // Without this, watch will not fire on the initial value
                hasLoaded: false,
                errorText: '',

                // This is two different forms of storing the same data
                // Raw binary data is necessary for the pdf.js viewer
                // Can not be a computed property b/c it requires an async/await operation
                deedOfTrustBinaryArray: null,
                deedOfTrustBlobUrl: null,

                ViewingState,
                numberOfPages: null,
                loadingText: 'Loading...',
                SessionAgent,
            }
        },
        computed: {
            // This is a watched value, your IDE may show it as 'unused'
            shouldFetchDocument: function () {
                if (this.agent === SessionAgent.notary) {
                    // Credentials are available AND we haven't already fetched a doc
                    return this.loanApplicationId && this.applicantId && !this.deedOfTrustBlobUrl && this.hasLoaded
                } else {
                    // We haven't already fetched a doc. Applicant doesn't need credentials
                    return !this.deedOfTrustBlobUrl && this.hasLoaded
                }
            },
            viewingState: function () {
                if (this.errorText) return ViewingState.error
                if (this.agent === SessionAgent.notary && !this.deedOfTrustBlobUrl) return ViewingState.loading
                // numberOfPages is the point that pdf.js has fully parsed the downloaded PDF
                if (this.agent === SessionAgent.applicant && !this.numberOfPages) return ViewingState.loading
                return ViewingState.showingIframe
            },
        },
        mounted: function () {
            this.init()
        },
        watch: {
            shouldFetchDocument: async function (newState, oldState) {
                logger.info(`Updated shouldFetchDocument from ${oldState} to ${newState}`)

                if (!newState) {
                    return
                }

                await this.fetchDeedOfTrustPdf()
            },
        },
        errorCaptured(err) {
            const stackLines = err.stack?.split('\n')
            const isKnownPdfJsWrapper = stackLines?.[0]?.includes(`TypeError: Cannot read properties of undefined (reading 'catch')`) && stackLines?.[1]?.includes('PDFJSWrapper.renderPage')
            if (isKnownPdfJsWrapper) {
                logger.warn(`Suppressing known vue-pdf TypeError`, null /* event */, err)
                // Suppress this error; it's a known issue in vue-pdf.
                // Based on LogRocket, it doesn't seem to affect applicants.
                // Based on Github, it doesn't seem like it'll be fixed any time soon:
                // https://github.com/FranckFreiburger/vue-pdf/issues/338
                return false
            }
            return true
        },
        methods: {
            init: async function () {
                // Reset our 'hasLoaded' state to trigger a refresh (if necessary)
                this.hasLoaded = false
                // Clear error text to trigger the loading screen
                this.errorText = null

                // Check if our LPD is likely to exist already
                const state = await this.getDocuSignState()
                // We're checking for envelopeId b/c this guarantees that the LPD is ready to go
                if (!state.envelopeId) {
                    logger.log('Missing docusign and/or property description, refusing to show deed of trust')
                    this.errorText = 'Missing docusign and/or property description'
                } else {
                    logger.log('Found property description + docusign, showing deed of trust')
                    this.hasLoaded = true
                }
            },
            getDocuSignState: async function () {
                let docuSignStateResponse
                try {
                    switch (this.agent) {
                        case SessionAgent.notary:
                            if (this.isMissingCredentials) {
                                logger.info('Credentials missing, aborting call to get notary DocuSign state')
                                return
                            }
                            docuSignStateResponse = await runWithRetryLogic(async () => {
                                return await getNotaryDocuSignState(this.applicantId, this.loanApplicationId)
                            }, 2)
                            break
                        case SessionAgent.applicant:
                            docuSignStateResponse = await runWithRetryLogic(async () => {
                                return await getApplicantDocuSignState()
                            }, 2)
                            break
                    }
                    assert(docuSignStateResponse.data?.payload?.state, 'Failed to retrieve DocuSign state')
                    return docuSignStateResponse.data.payload.state
                } catch (error) {
                    logger.error(`Error retrieving DocuSign state`, null /* event */, error)
                    this.errorText = error.message
                }
            },
            fetchDeedOfTrustPdf: async function () {
                this.errorText = null
                this.loadingText = 'Fetching deed of trust...'

                try {
                    logger.log('Attempting to fetch deed of trust...')

                    let response
                    if (this.agent === SessionAgent.applicant) {
                        response = await getLegalDocument('HelocDeedOfTrust')
                    } else {
                        response = await getLegalDocumentForNotary('HelocDeedOfTrust', this.loanApplicationId, this.applicantId)
                    }

                    const blob = response.data // as Blob
                    logger.info('Successfully retrieved deed of trust document blob, creating blob url')
                    this.deedOfTrustBlobUrl = URL.createObjectURL(blob)

                    if (this.agent === SessionAgent.applicant) {
                        logger.info('Created blob url, converting blob to array buffer')
                        const arrayBuffer = await blob.arrayBuffer()

                        // Load the raw uint-8 array into pdf.js
                        this.deedOfTrustBinaryArray = pdf.createLoadingTask({ data: arrayBuffer })

                        logger.info('Loading array buffer into pdf.js viewer')
                        const pdfData = await this.deedOfTrustBinaryArray.promise

                        this.numberOfPages = pdfData.numPages
                        logger.info(`pdf.js viewer rendered! Loaded ${this.numberOfPages} pages`)
                    }

                    this.$logEvent('event_successfully_downloaded_deed_of_trust', { deedOfTrustBlobUrl: this.deedOfTrustBlobUrl })
                } catch (e) {
                    logger.error(`Error fetching document for deed of trust`, null /* event */, e)
                    this.errorText = `We couldn't get the Deed of Trust`
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .deed-of-trust-container {
        height: 100%;
        overflow: auto;

        .deed-of-trust-error-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin: 0;
        }

        .deed-of-trust-pdf-container {
            height: 100%;
        }

        .deed-of-trust-iframe {
            height: 100%;
            width: 100%;
        }
    }
</style>
